import React, {FC, useEffect, useState} from 'react';
import "./scoreboard.css";
import CommonBob from "../../../components/bob/bob";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import useWebSocket from "react-use-websocket";
import CommonTextbox from "../../../components/textbox/textbox";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import Button from "../../../components/button/button";
import {BowbEvent} from "../../../models/models";
import {EventType} from "../../../models/events";

interface ScoreboardProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

const Scoreboard: FC<ScoreboardProperties> = ({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) => {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data?.lobby_code === currentState.lobby.code
            || event?.data?.lobby?.code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.NEXT_GAME) {
            setStartFade(true);
            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {...prevState.host, state: "Lobby"},
                    lobby: {...prevState.lobby, maxRounds: event.data.max_rounds}
                }));
            }, 500);
        }
    }, [lastJsonMessage]);

    const handlePlayAgain = () => {
        sendJsonMessage(
            {
                type: EventType.GAME_EVENT,
                subType: EventType.PLAY_AGAIN,
                data: {lobby_code: currentState.lobby.code}
            });
    }

    function playerListMaker() {
        console.log(currentState.lobby.playerScores)
        return Object.entries(currentState.lobby.playerScores).map(
            ([username, score]) => `${username}: ${score} \n`
        );
    }

    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <div className={"borp"}>
                    <CommonTextbox text={`Thanks for playing!`}></CommonTextbox>
                    <img className={"talking-stick"} src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                         alt={"talking shtick"}/>
                    <CommonBob></CommonBob>
                    <Button text={"Play again?"} action={handlePlayAgain}></Button>
                </div>

                <div className={"textbox-button"}>
                    {/*<Textbox text={`Scoreboard\n${playerListMaker()}`}></Textbox>*/}
                </div>
            </FadeInElement>
        </FadeOutElement>
    );
};

export default Scoreboard;
