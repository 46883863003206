import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "./winner.css";
import CommonTextbox from "../../../components/textbox/textbox";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../models/models";
import {EventType} from "../../../models/events";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import FadeInElement from "../../../components/fade-element/fade-in-element";

export default function WinnerScreenPlayer({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage} = webSocket
    const [startFadeConfetti, setStartFadeConfetti] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStartFadeConfetti(true);
        }, 3000);
    });

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data?.lobby_code === currentState.lobby.code
            || event?.data?.lobby?.code === currentState.lobby.code;

        if (validLobby && event?.subType === EventType.NEXT_GAME) {
            setStartFade(true);
            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    player: {...prevState.player, state: "Waiting"}
                }));
            }, 500);
        }
    }, [lastJsonMessage]);

    if (currentState.lobby.winner === currentState.player.username)
        return (
            <FadeOutElement startFade={startFade}>
                <FadeInElement startFade={true}>
                    <div className={"noSip"}>
                        <div className={"borp"}>
                            <CommonTextbox text={"Congratulations, you won! 🍺🥕"}></CommonTextbox>
                            <img className={"talking-stick"}
                                 src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                                 alt={"talking stick"}/>
                            <img className={"loading-gif"}
                                 src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`}
                                 alt={"Bob hopping indefinitely"}/>
                        </div>
                        <FadeOutElement startFade={startFadeConfetti}>
                            <img className={"fnaf-confetti"}
                                 src={`${process.env.PUBLIC_URL}/assets/img/fnaf-confetti.gif`} alt={"fnaf confetti"}/>
                        </FadeOutElement>
                    </div>
                </FadeInElement>
            </FadeOutElement>
        );
    else
        return (
            <FadeOutElement startFade={startFade}>
                <FadeInElement startFade={true}>
                    <div className={"noSip"}>
                        <div className={"borp"}>
                            <CommonTextbox text={"Bummer! Better luck next time 🥕"}></CommonTextbox>
                            <img className={"talking-stick"}
                                 src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                                 alt={"talking stick"}/>
                            <img className={"loading-gif"}
                                 src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`}
                                 alt={"Bob hopping indefinitely"}/>
                        </div>
                    </div>
                </FadeInElement>
            </FadeOutElement>
        );
}
