import React, {FC} from 'react';
import "./button.css";

interface CommonButtonProperties {
    text: string;
    action?: () => void;
    disabled?: boolean;
}

const CommonButton: FC<CommonButtonProperties> = ({text, action, disabled = false}) => {
    return (
        <>
            <button className={disabled ? "fancy-button disabled-dude" : "fancy-button"} onClick={action}
                    disabled={disabled}>
                {text}
            </button>
        </>
    );
};

export default CommonButton;