import React, {useEffect, useState} from 'react';
import "./no-connection.css";
import CommonBob, {BobType} from "../../../components/bob/bob";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import CommonTextbox from "../../../components/textbox/textbox";

const NoConnection = () => {
    const [startFade] = useState(false);
    const [count, setCount] = useState(50);

    useEffect(() => {
        const counter = setInterval(() => {
            setCount(prevCount => prevCount - 1);
        }, 1000);

        return () => {
            clearInterval(counter);
        };
    }, []);

    useEffect(() => {
        if (count <= 0)
            window.location.reload();
    }, [count]);

    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <div className={"tutorial-container"}>
                    <CommonBob bobType={BobType.Judging}></CommonBob>
                    <div className={"image-div"}>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick.png`} className={"talking-stick"}
                             alt={"pic of talking stick"}/>
                    </div>
                    <div className={"text-container"}>
                        <p>
                            There is currently no connection to the server. Try again in about a minute. {"\n\n"}
                            If you keep seeing this screen, the server may be offline.
                        </p>
                    </div>
                    <CommonTextbox text={`Retrying in ${count}..`}></CommonTextbox>
                </div>
            </FadeInElement>
        </FadeOutElement>
    );
};

export default NoConnection;
