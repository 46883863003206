import React, {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import {GameState} from "./states";

interface GameStateContextType {
    currentState: GameState;
    setCurrentState: Dispatch<SetStateAction<GameState>>;
}

const GameStateContext = createContext<GameStateContextType | undefined>(undefined);

export function useGameState() {
    const context = useContext(GameStateContext);
    if (!context) {
        throw new Error("useGameState must be used within a GameStateProvider");
    }
    return context;
}

export function GameStateProvider({children}: { children: React.ReactNode }) {
    const [currentState, setCurrentState] = useState<GameState>(new GameState());

    const updateState = (newState: Partial<GameState> | ((prevState: GameState) => GameState)) => {
        setCurrentState(prevState => ({
            ...prevState,
            ...(typeof newState === 'function' ? newState(prevState) : newState)
        }));
    };

    return (
        <GameStateContext.Provider
            value={{currentState: currentState, setCurrentState: updateState as Dispatch<SetStateAction<GameState>>}}>
            {children}
        </GameStateContext.Provider>
    );
}
