import React, {useEffect, useState} from 'react';
import {FadeElementProps} from "../../models/fade-models";

const extraDelayForDisplay = 10;

export default function FadeInElement({children, delay = 0, changeVisibility = true, startFade = undefined}
                                          : FadeElementProps) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (delay !== 0 && startFade === undefined) {
            const timeoutId = setTimeout(() => {
                setVisible(true);
            }, delay + extraDelayForDisplay);

            return () => clearTimeout(timeoutId);
        }
    }, [delay, startFade]);

    // Handle "startFade" action
    useEffect(() => {
        if (startFade) {
            const timeoutId = setTimeout(() => {
                setVisible(true);
            }, delay + extraDelayForDisplay);

            return () => clearTimeout(timeoutId);
        }
    }, [delay, startFade]);

    const elementStyle: React.CSSProperties = {
        display: !changeVisibility ? "block" : startFade || startFade === undefined ? 'block' : 'none', // so it doesn't take up space when it's invisible
        visibility: !changeVisibility ? "visible" : visible ? 'visible' : 'hidden',
        opacity: visible ? 1 : 0,
        transition: 'opacity 1s ease, visibility 1s ease'
    };

    return <div style={elementStyle}>{children}</div>;
}
