import useWebSocket from "react-use-websocket";
import {BowbEvent} from "../../../models/models";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
// @ts-ignore
import Modal from 'react-modal';
import {EventType} from "../../../models/events";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import CommonQr from "../../../components/qr/qr-code";
import CommonButton from "../../../components/button/button";
import "./lobby.css"
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import Toaster, {ToastService} from "../../../services/toaster.service";


export default function Lobby({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const {currentState, setCurrentState} = useGameState();
    const [startPlaying, setStartPlaying] = useState(false);
    const {lastJsonMessage, sendJsonMessage} = webSocket
    const [selectedRounds, setSelectedRounds] = useState<number>(10);
    const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal open/close

    const fileInputRef = useRef<HTMLInputElement>(null);

    // Only initially set the maxRounds to that what was already set. If it is 0, keep it to the initial 10.
    useEffect(() => {
        if (currentState.lobby.maxRounds > 0)
            setSelectedRounds(currentState.lobby.maxRounds)
    }, []);

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.JOINED_LOBBY_SUCCESS) {
            ToastService.success(event?.data.message);

            const playerList = [...currentState.host.players];
            playerList.push(event?.data.username)
            setCurrentState(prevState => ({
                ...prevState,
                host: {
                    ...prevState.host,
                    players: playerList
                }
            }));
        } else if (validLobby && event?.subType === EventType.JOINED_LOBBY_FAILURE) {
            ToastService.error(event?.data.message);
        } else if (validLobby && event?.subType === EventType.LEFT_LOBBY) {
            ToastService.info(event?.data.message);

            setCurrentState(prevState => ({
                ...prevState,
                host: {
                    ...prevState.host,
                    players: [...currentState.host.players].filter(playerName => playerName !== event?.data.username)
                }
            }));
        }  else if (validLobby && event?.subType === EventType.USER_REJOIN) {
            const playerList = [...currentState.host.players];
            playerList.push(event?.data.username)

            setCurrentState(prevState => ({
                ...prevState,
                currentQuote: { ...prevState.currentQuote, playersTotal: ++prevState.currentQuote.playersTotal },
                host: { ...prevState.host, players: playerList}
            }))

            ToastService.info(`User ${event.data.username} rejoined lobby.`);
        } else if (validLobby && event?.subType === EventType.IMPORT_USED_QUOTES) {
            ToastService.success(`Successfully imported ${event.data.amount} previously used quotes! These will be skipped.`);
        } else if (validLobby && event?.subType === EventType.EXPORT_USED_QUOTES) {
            handleUsedQuotesDownload(event?.data)
        }
    }, [lastJsonMessage]);

    const handleStartJudging = () => {
        setTimeout(() => {
            sendJsonMessage(
                {
                    type: EventType.GAME_EVENT,
                    subType: EventType.START_GAME,
                    data: {
                        lobby_code: currentState.lobby.code,
                        rounds: selectedRounds
                    }
                });

            setCurrentState(prevState => ({
                    ...prevState,
                    host: {...prevState.host, state: 'Tutorial'}
                })
            );
        }, 1200);
        setStartPlaying(true)
    }

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRounds(Number(event.target.value));
    };

    const handleUsedQuotesImport = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target?.result as string) as { used_quotes: [] };
                if (jsonData.used_quotes && Array.isArray(jsonData.used_quotes)) {

                    sendJsonMessage(
                        {
                            type: EventType.GAME_EVENT,
                            subType: EventType.IMPORT_USED_QUOTES,
                            data: {
                                lobby_code: currentState.lobby.code,
                                used_quotes: jsonData.used_quotes
                            }
                        });
                } else {
                    console.error("Invalid JSON format: 'usedQuotes' is not an array.");
                }
            } catch (error) {
                console.error("Error parsing JSON file: ", error);
            }
        };

        reader.readAsText(file);
    };

    const handleUsedQuotesExport = () => {
        sendJsonMessage(
            {
                type: EventType.GAME_EVENT,
                subType: EventType.EXPORT_USED_QUOTES,
                data: {
                    lobby_code: currentState.lobby.code
                }
            });
    };

    const handleUsedQuotesDownload = (usedQuotesJson: { used_quotes: [] }) => {
        if (usedQuotesJson.used_quotes.length === 0) {
            ToastService.info("There are no used quotes yet!");
            return;
        }

        const usedQuotesJsonString = JSON.stringify(usedQuotesJson, null, 2);
        const dataUrl = `data:text/json;charset=utf-8,${encodeURIComponent(usedQuotesJsonString)}`;
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `usedQuotes-${new Date().toLocaleDateString()}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        ToastService.success("Exported used quotes!");
    }

    return (
        <>
            <FadeOutElement startFade={startPlaying} delay={400}>
                <FadeInElement startFade={true}>
                    <div className={"hihinam"}>
                        <CommonQr lobbyCode={currentState.lobby.code}></CommonQr>
                        <div className={"judge-container"}>
                            <h2>Judges:</h2>
                            <p className={"judge-names"}>
                                {currentState.host.players.map((player, index) => (
                                    <span className={"player-name"} key={index}>{player}</span>
                                ))}
                            </p>
                            <div className={"bob-button"}>
                                <CommonButton action={handleStartJudging} text={"Start judging"}></CommonButton>
                            </div>
                        </div>
                    </div>
                    <div className={"icon-link"}>
                        <span className={"settings"} onClick={() => setModalIsOpen(true)}>⚙️</span>
                        <p id={"wswc-link"}>https://wswc.studiostoy.nl/<b>{currentState.lobby.code}</b> <img
                            className={"sniffing-bob"} src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`} alt="bob sniffing"/></p>
                    </div>
                </FadeInElement>
            </FadeOutElement>

            <Modal closeTimeoutMS={300} isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}
                   contentLabel="modal">
                <div className={"modal-content"}>
                    <div className={"header-content"}>
                        <h2>Settings</h2>
                        <a href="https://studiostoy.nl">© Studio Stoy</a>
                    </div>
                    <div className="dropdown">
                        <span>Amount of rounds:</span>
                        <select value={selectedRounds} onChange={handleDropdownChange}>
                            {[3, 5, 10, 25, 50, 75, 100].map((value) => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>

                    <div className={"import"}>
                        <input type="file" ref={fileInputRef} onChange={handleUsedQuotesImport} accept=".json"
                               style={{display: "none"}}/>
                        <CommonButton text="Import previously used quotes"
                                      action={() => fileInputRef?.current?.click()}/>
                    </div>

                    <div className={"export"}>
                        <CommonButton text="Export used quotes to save progress"
                                      action={handleUsedQuotesExport}/>
                    </div>

                    <CommonButton text={"Go back"} action={() => setModalIsOpen(false)}/>
                </div>
            </Modal>

            <Toaster/>
        </>
    );
}
