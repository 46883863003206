import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "./waiting.css";
import CommonTextbox from "../../../components/textbox/textbox";
import Toaster from "../../../services/toaster.service";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../models/models";
import {EventType} from "../../../models/events";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import FadeInElement from "../../../components/fade-element/fade-in-element";

export default function Waiting({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage} = webSocket

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.START_GAME) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({...prevState, player: {...prevState.player, state: 'Tutorial'}}))
            }, 500);
        } else if (validLobby && event?.subType === EventType.NEXT_QUOTE_FOUND) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    currentQuote: event.data.currentQuote,
                    host: {...prevState.host, state: "Playing"},
                    player: {...prevState.player, state: "Playing", rejoined: false}
                }));
            }, 500);
        } else if (validLobby && event?.subType === EventType.USER_REJOIN) {
            setCurrentState(prevState => ({
                ...prevState,
                player: {...prevState.player, rejoined: true}
            }));
        }

    }, [lastJsonMessage]);

    return (
        <FadeInElement startFade={true}>
            <FadeOutElement startFade={startFade}>
                <div className={"player-wait-holder"}>
                    <CommonTextbox text={`Hi ${currentState.player.username}!`}/>
                    {currentState.player.rejoined
                        ? <CommonTextbox text={`Waiting for the current round to finish...`}/>
                        : <CommonTextbox text={`Waiting for the host to start the game...`}/>
                    }
                    <img className={"loading-gif"}
                         src={"./assets/img/bob/bob-Hopping.gif"}
                         alt={"Bob hopping indefinitely"}/>

                    <Toaster/>
                </div>
            </FadeOutElement>
        </FadeInElement>
    );
}
