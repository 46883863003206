import useWebSocket from "react-use-websocket";
import {BowbEvent} from "../../../models/models";
import React, {useEffect, useState} from "react";
import {EventType} from "../../../models/events";
import CommonButton from "../../../components/button/button";
import 'react-toastify/dist/ReactToastify.css';
import Toaster, {ToastService} from "../../../services/toaster.service";
import "./join.css";
import CommonTextbox from "../../../components/textbox/textbox";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";


export default function Join({webSocket, lobbyCode}: {
    webSocket: ReturnType<typeof useWebSocket>,
    lobbyCode: string
}) {
    const {setCurrentState} = useGameState();
    const [selectedTempClient, setSelectedTempClient] = useState<string>("");
    const [possibleTempClients, setPossibleTempClients] = useState<string[]>([]);
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    const [username, setUsername] = useState('');

    let event: BowbEvent = {type: "none", subType: "none", data: {}};
    useEffect(() => {
        event = lastJsonMessage as BowbEvent;
        if (event?.subType === EventType.USER_JOIN) {
            sessionStorage.setItem("userId", event.data.user_id)
            setPossibleTempClients(event.data.possible_clients)
        } else if (event?.subType === EventType.USER_REJOIN) {
            setCurrentState(prevState => ({
                ...prevState,
                lobby: {...prevState.lobby, code: event.data.lobby_code},
                player: {...prevState.player, state: 'Waiting', username: event.data.username, rejoined: true},
                currentQuote: {
                    ...prevState.currentQuote,
                    playersTotal: ++prevState.currentQuote.playersTotal
                }
            }))
        } else if (event?.subType === EventType.JOINED_LOBBY_SUCCESS && event?.data.user_id === sessionStorage.getItem("userId")) {
            setCurrentState(prevState => ({
                ...prevState,
                lobby: {...prevState.lobby, code: event.data.lobby_code},
                player: {...prevState.player, state: 'Waiting', username: event.data.username}
            }))
        } else if (event?.subType === EventType.JOINED_LOBBY_FAILURE) {
            ToastService.error(event.data.message);
        }
    }, [lastJsonMessage]);

    function joinLobby() {
        const selectedUsername = selectedTempClient !== ""
            ? selectedTempClient
            : username.trim().slice(0, 15).replace(/[<>,]/g, '');

        setUsername(selectedUsername);

        sendJsonMessage({
            type: EventType.LOBBY_EVENT,
            subType: EventType.JOIN_LOBBY,
            data: {
                lobby_code: lobbyCode,
                user_id: sessionStorage.getItem("userId"),
                username: selectedUsername
            }
        });
    }

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setUsername(event.target.value.replace(/[<>,`]/g, '').slice(0, 15).trim());

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTempClient(String(event.target.value));
    };

    const charactersLeft = 15 - username.length;
    return (
        <div className="player-lobby-holder">
            <p>Lobby: <b>{lobbyCode}</b></p>
            <CommonTextbox text="Welcome! Please enter your name:"/>
            <span className="name-options">
                {selectedTempClient === "" &&
                    <span className="red-box">
                        {charactersLeft}
                    </span>
                }

                {possibleTempClients.length > 0 &&
                    <span className="options">
                        <span className={"red-box"}>Rejoin as:</span>
                        <span className="dropdown">
                            <select value={selectedTempClient} onChange={handleDropdownChange}>
                                {[""].concat(possibleTempClients).map((value) => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </select>
                        </span>
                    </span>
                }
            </span>

            {selectedTempClient === "" &&
                <input type="text" placeholder="Cheebsbal" value={username} onChange={handleUsernameChange}
                       className="cool-input"/>
            }

            <div className="become-button">
                <CommonButton text="Become a judge" action={joinLobby} disabled={username.length <= 0 && selectedTempClient === ""}/>
            </div>

            <Toaster/>
        </div>
    );
}
