import React, {FC} from 'react';
import "./qr-code.css";

interface CommonQrProperties {
    lobbyCode: string;
}

const CommonQr: FC<CommonQrProperties> = ({lobbyCode}) => {
    return (
        <>
            <img className={"common-qr"} src={`${process.env.PUBLIC_URL}/assets/img/qr/lobby-${lobbyCode}.svg`}
                 alt={"qr bowb"}></img>
        </>
    );
};

export default CommonQr;
