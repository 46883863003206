import React, {FC, useEffect, useState} from 'react';
import "./sipping-time.css";
import CommonBob from "../../../components/bob/bob";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import CommonTextbox from "../../../components/textbox/textbox";
import Textbox from "../../../components/textbox/textbox";
import {EventType} from "../../../models/events";
import {BowbEvent} from "../../../models/models";

interface SippingTimeProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

const noSipsSentences = [
    "I wonder what that says about them..🍸",
    "That obvious, huh?🍷",
    "They are.. famous? At least for something..🥤",
    "Maybe don't try for politics. 🥕"
]
const allSipsSentences = [
    "Maybe I should find some better judges..🍹",
    "I hope you're not drunk at the job..🍷",
    "They are.. famous!🥂 At least for something..🥤",
    "How did you all even get this job??🍹"
]
const oneSipsSentences = [
    "This individual made a grave mistake! \nTwo sips for them! 🥕",
    "It seems there is one (1) judge who got it wrong.. \nMake them sip twice! 🥕🍹",
    "For being the only one to get this wrong, \nyou get to do TWO (2) sips!🍹🍹",
    "One sip for being wrong. \n Another sip for being the only one! 🥤🥤"
]

const SippingTime: FC<SippingTimeProperties> = ({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) => {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const [randomInt, setRandomInt] = useState(-1);
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    const playersToSipText: string = currentState.currentQuote.playersToSip
        .map((word, index) => {
            if (index === currentState.currentQuote.playersToSip.length - 1) {
                return `<${word}>`;
            } else {
                return `<${word}>,`;
            }
        })
        .join(" ")
        .replace(/,([^,]*)$/, ' and$1');

    useEffect(() => {
        if (randomInt === -1)
            setRandomInt(Math.floor(Math.random() * 4));
    }, [randomInt]);

    useEffect(() => {
        if (currentState.currentQuote.playersToSip.length <= 0)
            setTimeout(() => {
                sendJsonMessage(
                    {
                        type: EventType.GAME_EVENT,
                        subType: EventType.NEXT_QUOTE,
                        data: {lobby_code: currentState.lobby.code}
                    });
            }, 3000);
    }, [currentState.currentQuote.playersToSip.length, currentState.lobby.code, sendJsonMessage]);

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data?.lobby_code === currentState.lobby.code
            || event?.data?.lobby?.code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.NEXT_QUOTE_FOUND) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    currentQuote: event.data.currentQuote,
                    host: {...prevState.host, state: "Playing"},
                    player: {...prevState.player, state: "Playing"}
                }));
            }, 500);
        } else if (validLobby && event?.subType === EventType.ANNOUNCE_WINNER) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    lobby: {
                        ...prevState.lobby,
                        winner: event?.data.lobby.winner,
                        playerScores: event?.data.player_scores
                    },
                    host: {...prevState.host, state: "Winner"},
                    player: {...prevState.player, state: "Winner"}
                }));
            }, 500);
        } else if (validLobby && event?.subType === EventType.LEFT_LOBBY) {
            setCurrentState(prevState => ({
                ...prevState,
                host: {
                    ...prevState.host,
                    players: prevState.host.players.filter(player => player !== event.data.username)
                },
                currentQuote: {
                    ...prevState.currentQuote,
                    playersTotal: prevState.currentQuote.playersTotal - 1,
                    playersToSip: prevState.currentQuote.playersToSip.filter(player => player !== event.data.username)
                }
            }));
        }
    }, [lastJsonMessage, startFade, currentState.lobby.code, setCurrentState]);

    // Separate message if everyone lost.
    if (currentState.currentQuote.playersToSip.length === currentState.currentQuote.playersTotal) {
        return (
            <FadeOutElement startFade={startFade}>
                <FadeInElement startFade={true}>
                    <div className={"borp"}>
                        <CommonTextbox text={`You all have to sip! \n ${allSipsSentences[randomInt]}`}></CommonTextbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                             alt={"talking shtick"}/>
                        <CommonBob></CommonBob>
                    </div>
                    <div className={"sip-container"}>
                        <Textbox text={playersToSipText}></Textbox>
                    </div>
                </FadeInElement>
            </FadeOutElement>
        )
    }

    if (currentState.currentQuote.playersToSip.length === 1) {
        return (
            <FadeOutElement startFade={startFade}>
                <FadeInElement startFade={true}>
                    <div className={"borp"}>
                        <CommonTextbox text={`${oneSipsSentences[randomInt]}`}></CommonTextbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                             alt={"talking shtick"}/>
                        <CommonBob></CommonBob>
                    </div>
                    <div className={"sip-container"}>
                        <Textbox text={playersToSipText}></Textbox>
                    </div>
                </FadeInElement>
            </FadeOutElement>
        )
    }

    // Otherwise, tell who and if they should sip.
    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <div className={"borp"}>
                    {playersToSipText !== ""
                        ? <CommonTextbox text={`🍻These judges will have to sip!🍻`}></CommonTextbox>
                        : <CommonTextbox text={`No one has to sip! \n ${noSipsSentences[randomInt]}`}></CommonTextbox>
                    }
                    <img className={"talking-stick"} src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                         alt={"talking shtick"}/>
                    <CommonBob></CommonBob>
                </div>
                {playersToSipText !== ""
                    ? <div className={"sip-container"}>
                        <Textbox text={playersToSipText}></Textbox>
                    </div>
                    : <div></div>
                }

            </FadeInElement>
        </FadeOutElement>
    );
};

export default SippingTime;
