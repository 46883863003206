import React from "react";
import useWebSocket from "react-use-websocket";
import {QueryParams} from "react-use-websocket/dist/lib/types";

const WS_URL = process.env.REACT_APP_WS_URL ?? "ws://localhost:8000";

interface WebSocketProviderProps {
    children: (webSocket: ReturnType<typeof useWebSocket>) => React.ReactNode}

const WebSocketProvider: React.FC<WebSocketProviderProps> = ({children}) => {
    // If the user has a username defined in localStorage, they may be trying to reconnect.
    // It'll be passed to the connection request.
    const params: QueryParams = {};
    params["username"] = localStorage.getItem("username") ?? "";

    const webSocket = useWebSocket(WS_URL, {
        queryParams: params,
        onOpen: () => {
            console.log("WebSocket connection established.");
        },
        share: true,
    });

    return <>{children(webSocket)}</>;
};

export default WebSocketProvider;
