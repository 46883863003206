import React, {FC, useEffect, useState} from 'react';
import "./sipping-time.css";
import CommonBob from "../../../components/bob/bob";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import CommonTextbox from "../../../components/textbox/textbox";
import CommonButton from "../../../components/button/button";
import {EventType} from "../../../models/events";
import {BowbEvent} from "../../../models/models";

interface SippingTimeProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

const sipSentences = [
    "Nope! Time for a refreshment 🍺",
    "Thou hath wronged. \nSip for your sins! 🍺",
    "*Sipsipsip* 🍺",
    "Cheers! 🍺",
]

const sippedSentences = [
    "*Slörp* 🍺",
    "Ah, refreshing! 🍺",
    "*Sipsipsip* 🍺",
    "Cheers! 🍺"
]

const correctSentences = [
    "You're lucky this time! 🥕",
    "Correct! 🥕",
    "Are you thirsty? Too bad, you got it! 🥕",
    "Well, that was an obvious one. 🥕"
]

const SippingTimePlayer: FC<SippingTimeProperties> = ({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) => {
    const [startFade, setStartFade] = useState(false);
    const [sipped, setSipped] = useState(false);
    const [randomInt, setRandomInt] = useState(-1);
    const {currentState, setCurrentState} = useGameState();
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    useEffect(() => {
        if (randomInt === -1)
            setRandomInt(Math.floor(Math.random() * 4));

        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data?.lobby_code === currentState.lobby.code
            || event?.data?.lobby?.code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.NEXT_QUOTE_FOUND) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    currentQuote: event.data.currentQuote,
                    host: {...prevState.host, state: "Playing"},
                    player: {...prevState.player, state: "Playing"}
                }));
            }, 500);
        } else if (validLobby && event?.subType === EventType.ANNOUNCE_WINNER) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    lobby: event.data.lobby,
                    host: {...prevState.host, state: "Winner"},
                    player: {...prevState.player, state: "Winner"}
                }));
            }, 500);
        }
    }, [lastJsonMessage, startFade]);

    function hasSipped() {
        setSipped(true);

        sendJsonMessage(
            {
                type: EventType.GAME_EVENT,
                subType: EventType.SIPPED,
                data: {
                    lobby_code: currentState.lobby.code,
                    username: currentState.player.username
                }
            });
    }

    // If the player got it wrong, and has to sip.
    if (currentState.currentQuote.playersToSip.includes(currentState.player.username)) {
        return (
            <FadeOutElement startFade={startFade}>
                <FadeInElement startFade={true}>

                    {!sipped
                        ? <div className={"hasToSip"}>
                            <div className={"borp"}>
                                <CommonTextbox text={sipSentences[randomInt]}></CommonTextbox>
                                <img className={"talking-stick"}
                                     src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                                     alt={"talking stick"}/>
                                <CommonBob></CommonBob>
                            </div>
                            <div className={"sip-button"}>
                                <CommonButton text={"I sipped"} action={hasSipped}></CommonButton>
                            </div>
                            <img className={"drink"}
                                 src={`${process.env.PUBLIC_URL}/assets/img/sipping-cats/cat-${Math.floor(Math.random() * 6) + 1}.gif`}
                                 alt={"slorp"}/>
                        </div>
                        : <div className={"noSip"}>
                            <div className={"borp"}>
                                <CommonTextbox
                                    text={`${sippedSentences[randomInt]}\n(waiting until everyone sipped)`}></CommonTextbox>
                                <img className={"talking-stick"}
                                     src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                                     alt={"talking stick"}/>
                                <img className={"loading-gif"}
                                     src={"./assets/img/bob/bob-Hopping.gif"}
                                     alt={"Bob hopping indefinitely"}/>
                            </div>
                        </div>
                    }

                </FadeInElement>
            </FadeOutElement>
        );
    }

    // If they got it right.
    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <div className={"noSip"}>
                    <div className={"borp"}>
                        <CommonTextbox text={`${correctSentences[randomInt]}`}></CommonTextbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                             alt={"talking stick"}/>
                        <img className={"loading-gif"}
                             src={"./assets/img/bob/bob-Hopping.gif"}
                             alt={"Bob hopping indefinitely"}/>
                    </div>
                </div>
            </FadeInElement>
        </FadeOutElement>
    )
};

export default SippingTimePlayer;
