import React, {FC} from 'react';
import "./gang-member-choice.css";
import {useGameState} from "../../hooks/use-game-state/use-game-state";

interface ChoiceProperties {
    action?: (authorName: string) => void;
}

const GangMemberChoice: FC<ChoiceProperties> = ({action}) => {
    const {currentState} = useGameState();

    return (
        <div className={"choices"}>
            {currentState.currentQuote.options.map((author, index) =>
                <img src={`${process.env.PUBLIC_URL}/assets/img/gang-members/${author}.jpg`}
                     key={index}
                     className={"choice"}
                     alt={author}
                     onClick={() => action ? action(author) : undefined}
                ></img>
            )}
        </div>
    );
};

export default GangMemberChoice;
