import React, {FC} from 'react';
import './bob.css';

export enum BobType {
    Sitting,
    Judging,
    Sniffing,
    Hopping
}

interface CommonBobProperties {
    bobType?: BobType;
}

const CommonBob: FC<CommonBobProperties> = (props) => {
    return (
        <div className={"bob"}>
            {props.bobType === undefined || props.bobType === BobType.Sitting
                ? <img className={"small-bob"} src={`${process.env.PUBLIC_URL}/assets/img/bob/bob.png`}
                       alt={"literally bob"}/>
                : <img className={"big-bob"}
                       src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-${BobType[props.bobType]}.gif`}
                       alt={"literally bob"}/>
            }
        </div>
    );
};

export default CommonBob;
