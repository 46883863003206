import React from "react";
import Lobby from "../lobby/lobby";
import "./host-page.css"
import Start from "../start/start";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import Tutorial from "../../common/tutorial/tutorial";
import MusicControl from "../../../components/music-control/music-control";
import useWebSocket from "react-use-websocket";
import Playing from "../playing/playing";
import Answer from "../../common/answer/answer";
import SippingTime from "../sipping-time/sipping-time";
import Winner from "../winner/winner";
import Scoreboard from "../scoreboard/scoreboard";
import NoConnection from "../../common/no-connection/no-connection";

export default function HostPage({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const {currentState} = useGameState();

    if (!webSocket.readyState || webSocket.readyState === 3)
        return <NoConnection/>

    switch (currentState.host.state) {
        case "Start":
            return <><Start webSocket={webSocket}/> <MusicControl/> </>;
        case 'Lobby':
            return <><Lobby webSocket={webSocket}/> <MusicControl/> </>;
        case 'Tutorial':
            return <><Tutorial webSocket={webSocket} host={true}/> <MusicControl/> </>;
        case 'Playing':
            return <><Playing webSocket={webSocket}/> <MusicControl/> </>;
        case 'Answer':
            return <><Answer webSocket={webSocket} host={true}/> <MusicControl/> </>;
        case 'SippingTime':
            return <><SippingTime webSocket={webSocket}/> <MusicControl/> </>;
        case 'Winner':
            return <><Winner webSocket={webSocket}/> <MusicControl/> </>;
        case 'Scoreboard':
            return <><Scoreboard webSocket={webSocket}/> <MusicControl/> </>;
        default:
            return <div>Error: Invalid game state</div>;
    }
}
