import React, {FC, useEffect, useState} from 'react';
import "./winner.css";
import CommonBob from "../../../components/bob/bob";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import useWebSocket from "react-use-websocket";
import CommonTextbox from "../../../components/textbox/textbox";
import Textbox from "../../../components/textbox/textbox";
import AudioService from "../../../services/audio.service";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import CommonButton from "../../../components/button/button";
import {start} from "repl";

interface WinnerScreenProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

const Winner: FC<WinnerScreenProperties> = ({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) => {
    const [startFade, setStartFade] = useState(false);
    const [startFadeConfetti, setStartFadeConfetti] = useState(false);
    const {currentState, setCurrentState} = useGameState();

    useEffect(() => {
        if (!startFade && !startFadeConfetti)
            AudioService.playEffect("soundeffects/fnaf-yay").then(r => (r))

        setTimeout(() => {
            setStartFadeConfetti(true);
        }, 3000);
    });

    const handleContinue = () => {
        setStartFade(true);

        setTimeout(() => {
            setCurrentState(prevState => ({
                ...prevState,
                host: {...prevState.host, state: "Scoreboard"},
                player: {...prevState.player, state: "Scoreboard"}
            }));
        }, 500);
    }

    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <div className={"everything-container"}>
                    <div className={"borp"}>
                        <CommonTextbox text={`The winner is...`}></CommonTextbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                             alt={"talking shtick"}/>
                        <CommonBob></CommonBob>
                    </div>

                    <div className={"win-container"}>
                        <Textbox text={`${currentState.lobby.winner}!`}></Textbox>
                    </div>
                    <div className={"choose-container"}>
                        <Textbox text={`${currentState.lobby.winner} may now choose 3 people who have to drink.`}></Textbox>
                    </div>

                    <CommonButton text={"Continue"} action={handleContinue}/>

                    <FadeOutElement startFade={startFadeConfetti}>
                        <img className={"fnaf-confetti"} src={`${process.env.PUBLIC_URL}/assets/img/fnaf-confetti.gif`}
                             alt={"fnaf confetti"}/>
                    </FadeOutElement>
                </div>
            </FadeInElement>
        </FadeOutElement>
    );
};

export default Winner;
