import React, {FC} from 'react';
import "./cancelled-stamp.css";
import StampElement from "../../../components/stamp-transition/stamp-transition";

interface CommonStampProperties {
}

const Stamp: FC<CommonStampProperties> = () => {
    return (
        <StampElement delay={1500}>
            <div className={"stamp"}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/cancelled.png`} alt={"cancelled stamp"}/>
            </div>
        </StampElement>
    );
};

export default Stamp;
