import React, {useEffect, useState} from 'react';
import {FadeElementProps} from "../../models/fade-models";

export default function FadeOutElement({children, delay = 0, changeVisibility = true, startFade}: FadeElementProps) {
    const [fadeOut, setFadeOut] = useState(false);
    const [afterVisible, setAfterVisible] = useState(true);

    useEffect(() => {
        if (delay !== 0 && startFade === undefined) {
            setTimeout(() => setAfterVisible(false), delay + 1000)

            const timeoutId = setTimeout(() => {
                setFadeOut(true);
            }, delay);

            return () => clearTimeout(timeoutId);
        }
    }, [delay, startFade]);

    // Handle "startFade" action
    useEffect(() => {
        if (fadeOut || startFade) {
            const exitTimeout = setTimeout(() => {
                setFadeOut(true);
            }, delay);

            setTimeout(() => {
                setAfterVisible(false)
            }, delay + 500)

            return () => clearTimeout(exitTimeout);
        }
    }, [delay, fadeOut, startFade]);

    const elementStyle: React.CSSProperties = {
        display: !changeVisibility ? "block" : afterVisible ? 'block' : 'none',
        opacity: fadeOut ? 0 : 1,
        transition: 'opacity 1s ease',
    };

    return <div style={elementStyle}>{children}</div>;
}
