import React, {FC, useState} from 'react';
import "./music-control.css";
import AudioService from "../../services/audio.service";

const MusicControl: FC = () => {
    let started: boolean = false;
    const [muted, setMuted] = useState(false);

    const toggleMute = async () => {
        if (!started && !muted)
            await AudioService.playMusic("soundtrack/WhoShouldWeCancel.mp3")

        setMuted(!muted);

        AudioService.setMusicVolume(muted ? 0 : 1);
        AudioService.mute(muted);
    };

    return (
        <div className={"music-container"}>
            <button className={`mute-toggle ${muted ? 'muted' : 'unmuted'}`} onClick={toggleMute}>
                <span className="icon">{muted ? '🔊' : '🔇'}</span>
            </button>
        </div>
    );
};

export default MusicControl;
