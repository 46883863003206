import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes, useParams} from "react-router-dom";
import HostPage from "./pages/host/host-control-page/host-page";
import PlayerPage from "./pages/player/player-control-page/player-page";
import {GameStateProvider} from "./hooks/use-game-state/use-game-state";
import WebSocketProvider from "./hooks/WebSocketProvider";

export default function App() {
    return (
        <BrowserRouter>
            <WebSocketProvider>
                {(webSocket) => (
                    <GameStateProvider>
                        <Routes>
                            <Route index element={<HostPage webSocket={webSocket}/>}/>
                            <Route path="/:lobbyCode" element={<PlayerPage webSocket={webSocket}/>}/>
                            <Route path="*" element={<HostPage webSocket={webSocket}/>}/>
                        </Routes>
                    </GameStateProvider>
                )}
            </WebSocketProvider>
        </BrowserRouter>
    )
}
