import React, {FC, useEffect, useState} from 'react';
import "./answer.css";
import CommonBob from "../../../components/bob/bob";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import CommonTextbox from "../../../components/textbox/textbox";
import Stamp from "../cancelled-stamp/cancelled-stamp";
import AudioService from "../../../services/audio.service";
import {EventType} from "../../../models/events";
import {BowbEvent} from "../../../models/models";

const {setTimeout} = window

interface CommonAnswerProperties {
    webSocket: ReturnType<typeof useWebSocket>;
    host: boolean;
}

const Answer: FC<CommonAnswerProperties> = ({webSocket, host}: {
    webSocket: ReturnType<typeof useWebSocket>,
    host: boolean
}) => {
    const [startFade, setStartFade] = useState(false);
    const [timerToSipId, setTimerToSipId] = useState(0);
    const {currentState, setCurrentState} = useGameState();
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    // EVENTS
    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent
        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.TIME_TO_SIP) {
            setTimerToSipId(window.setTimeout(() => {
                handleNextState()
            }, 8000));
        }
        if (!host && validLobby && event?.subType === EventType.TIME_TO_SIP_NOW) {
            window.clearTimeout(timerToSipId);
            handleNextState();
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        // Host playing random sound between 1 and 10, inclusive.
        if (host && timerToSipId !== 0) {
            AudioService.playRandomEffect(() => {
                window.clearTimeout(timerToSipId);
                if (!startFade) {
                    sendJsonMessage({
                        type: EventType.GAME_EVENT,
                        subType: EventType.TIME_TO_SIP_NOW,
                        data: {lobby_code: currentState.lobby.code}
                    });
                    handleNextState();
                }

            }).then(r => (r));
        }
    }, [timerToSipId]);

    function handleNextState() {
        setStartFade(true);

        setTimeout(() => {
            setCurrentState(prevState => ({
                ...prevState,
                player: {...prevState.player, state: 'SippingTime'},
                host: {...prevState.host, state: 'SippingTime'}
            }));
        }, 500);
    }

    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <div className={"borp"}>
                    <CommonTextbox text={`It is ${currentState.currentQuote.quoteData.author}!`}></CommonTextbox>
                    <img className={"talking-stick"} src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                         alt={"talking stick"}/>
                    <CommonBob></CommonBob>
                </div>
                <div className={"answer-container"}>
                    <Stamp></Stamp>
                    <img className={"quote-author"}
                         src={`${process.env.PUBLIC_URL}/assets/img/gang-members/${currentState.currentQuote.quoteData.author}.jpg`}
                         alt={"talking stick"}/>
                </div>
            </FadeInElement>
        </FadeOutElement>
    );
};

export default Answer;
