import React, {useEffect, useState} from 'react';
import {StampProps} from "../../models/stamp-model";

const extraDelayForDisplay = 10;

export default function StampElement({children, delay = 0, changeVisibility = true, startStamp = undefined}
                                         : StampProps) {
    const [enhance, setEnhance] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (delay !== 0 && startStamp === undefined) {
            const timeoutId = setTimeout(() => {
                setEnhance(true);
                setVisible(true);
            }, delay + extraDelayForDisplay);

            return () => clearTimeout(timeoutId);
        }
    }, [delay, startStamp]);

    // Handle "startStamp" action
    useEffect(() => {
        if (startStamp) {
            const timeoutId = setTimeout(() => {
                setEnhance(true);
            }, delay + extraDelayForDisplay);

            return () => clearTimeout(timeoutId);
        }
    }, [delay, startStamp]);

    const elementStyle: React.CSSProperties = {
        display: !changeVisibility ? "flex" : startStamp || startStamp === undefined ? 'flex' : 'none', // so it doesn't take up space when it's invisible
        transform: enhance ? 'scale(1.0)' : 'scale(10.0)',
        transition: 'transform 0.5s linear',
        transformOrigin: 'top right',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: visible ? 1 : 0,
    };

    return <div style={elementStyle}>{children}</div>;
}
