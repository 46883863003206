import {QuoteWithAuthor} from "../../models/models";

export type GameStateValue =
    "Start"
    | "Lobby"
    | "Waiting"
    | "Tutorial"
    | "Playing"
    | "Answer"
    | "SippingTime"
    | "Winner"
    | "Scoreboard";

export type AuthorOption =
    "Arvit"
    | "Bino"
    | "Dyllo"
    | "Ef"
    | "Japspser"
    | "Jordt"
    | "Joyo"
    | "Sanda"
    | "Soeb"
    | "Steezer"
    | "Naoh"

    // | "Coc"
    // | "Bowb";

export class GameState {
    host: { state: GameStateValue, players: string[] } = {
        state: "Start",
        players: []
    };
    player: { state: GameStateValue, username: string, rejoined: boolean } = {
        username: "",
        state: "Start",
        rejoined: false
    };
    lobby: { code: string, currentRound: number, maxRounds: number, winner: string, playerScores: { [username: string]: number } } = {
        code: "",
        currentRound: 0,
        maxRounds: 0,
        winner: "",
        playerScores: {}
    }
    currentQuote: {
        question: string;
        quoteData: QuoteWithAuthor,
        options: AuthorOption[];
        playersTotal: number,
        playersToAnswer: number,
        playersToSip: string[]
    } = {
        question: "Who spoke so eloquently?",
        quoteData: {quoteText: "Ik eet voeten", author: "Soeb", imageName: ""},
        options: ["Bino", "Soeb", "Arvit", "Jordt"],
        playersTotal: 0,
        playersToAnswer: 0,
        playersToSip: [],
    }
}
